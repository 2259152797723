/** @jsx jsx */
import { jsx, Flex, Box, NavLink } from "theme-ui"
import PropTypes from "prop-types"
import Link from "./link"
import EutactLogo from "../assets/svg/eutact-logo.inline.svg"
import HamburgerIcon from "../assets/svg/hamburger.inline.svg"
import HamburgerOpenIcon from "../assets/svg/hamburger-open.inline.svg"
import { useState, useRef, useCallback } from "react"
import { useOutsidePress } from "../utils/hooks"

const Header = ({ shouldScroll }) => {
  const [isOpen, setOpen] = useState(false)
  const wrapperRef = useRef(null)

  useOutsidePress(wrapperRef, setOpen.bind(this, false))

  return (
    <Box
      ref={wrapperRef}
      as="nav"
      bg="dark"
      sx={{
        position: "fixed",
        width: "100%",
        top: "0px",
        zIndex: 20,
      }}>
      <Flex
        px={3}
        sx={{
          flexDirection: ["column", null, "row", null],
          justifyContent: "space-between",
          alignItems: "center",
        }}>
        <Flex
          py={3}
          sx={{
            width: "100%",
            flex: 1,
            justifyContent: "space-between",
            alignItems: "center",
          }}>
          <Link to="/" aria-label="Eutact logo">
            <EutactLogo
              sx={{
                color: "textHighlight",
                height: (t) => `${t.space[5] * 0.8}px`,
                width: "auto",
              }}
            />
          </Link>

          <NavLink
            onClick={() => setOpen(!isOpen)}
            aria-label={() => `${isOpen ? "Close drawer" : "Open drawer"}`}
            sx={{
              bg: "unset",
              p: "unset",
            }}>
            {isOpen ? (
              <HamburgerOpenIcon
                sx={{
                  "display": ["inline", null, "none", null],
                  "color": "textHighlight",
                  "width": (t) => `${t.space[4]}px`,
                  "transition": `color ease 0.3s`,
                  "&:hover": {
                    color: "primary",
                    cursor: "pointer",
                  },
                }}
              />
            ) : (
              <HamburgerIcon
                sx={{
                  "display": ["inline", null, "none", null],
                  "color": "textHighlight",
                  "width": (t) => `${t.space[4]}px`,
                  "transition": `color ease 0.3s`,
                  "&:hover": {
                    color: "primary",
                    cursor: "pointer",
                  },
                }}
              />
            )}
          </NavLink>
        </Flex>
        <Flex
          sx={{
            height: [
              (t) => `${isOpen ? t.space[6] + "px" : "0px"}`,
              null,
              "auto",
              null,
            ],
            opacity: [`${isOpen ? "100%" : "0%"}`, null, "100%", null],
            flexDirection: ["column", null, "row", null],
            transition: `all ease 0.3s`,
          }}>
          <SmoothNavLink
            title="Who we are"
            href="#who-we-are"
            shouldScroll={shouldScroll}
            callback={setOpen.bind(this, false)}
          />
          <SmoothNavLink
            title="What we do"
            href="#what-we-do"
            shouldScroll={shouldScroll}
            callback={setOpen.bind(this, false)}
          />
          <SmoothNavLink
            title="Contact Us"
            href="#contact-form"
            shouldScroll={shouldScroll}
            callback={setOpen.bind(this, false)}
          />
          <Box
            sx={{
              height: "1px",
              pt: 2,
              borderStyle: "solid",
              borderWidth: "0px",
              borderBottomWidth: "1px",
              borderColor: "textHighlight",
              display: ["inline-block", null, "none", null],
            }}
          />
        </Flex>
      </Flex>
    </Box>
  )
}

Header.propTypes = {
  shouldScroll: PropTypes.bool,
}

Header.defaultProps = {
  shouldScroll: true,
}

export default Header

const SmoothNavLink = (props) => {
  const { title, href, shouldScroll, callback } = props
  // create memoized callback
  const scroll = useCallback(
    (e) => {
      e.preventDefault()
      callback()
      document.querySelector(href).scrollIntoView({
        behavior: "smooth",
      })
    },
    [href, callback]
  )
  return (
    <NavLink
      {...props}
      p={3}
      href={shouldScroll ? null : `/${href}`}
      onClick={shouldScroll ? scroll : null}
      sx={{
        cursor: "pointer",
        transition: "color ease 0.3s",
      }}>
      {title}
    </NavLink>
  )
}
