/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import { Box } from "theme-ui"
import Footer from "./footer"

const Layout = ({ showHeader, scrollOnNavLink, children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      {showHeader ? (
        <Header
          siteTitle={data.site.siteMetadata.title}
          shouldScroll={scrollOnNavLink}
        />
      ) : null}
      <Box>
        <Box as="main">{children}</Box>
      </Box>
      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  showHeader: PropTypes.bool,
  scrollOnNavLink: PropTypes.bool,
}

Layout.defaultProps = {
  showHeader: true,
  scrollOnNavLink: true,
}

export default Layout
