/** @jsx jsx */
import { jsx, Box, Container, Text, Flex } from "theme-ui"
import Link from "./link"

const Footer = () => (
  <Box as="footer" bg="highlight">
    <Container>
      <Flex
        sx={{
          flexDirection: ["column", "row", null, null],
          justifyContent: "space-between",
          alignItems: "center",
        }}>
        <Box p={2}>
          <Link
            to="/privacy-policy"
            sx={{
              variant: "text.imprint",
            }}>
            Privacy Policy
          </Link>
          {/* <span role="img" aria-label="love">
            •
          </span>
          <Link
            to="/privacy-policy"
            sx={{
              variant: "text.imprint",
            }}>
            Privacy Policy
          </Link> */}
        </Box>
        <Text
          variant="text.imprint"
          p={2}
          sx={{
            textAlign: "center",
          }}>
          Copyright reserved {new Date().getFullYear()}
        </Text>
      </Flex>
    </Container>
  </Box>
)

Footer.propTypes = {}

Footer.defaultProps = {}

export default Footer
